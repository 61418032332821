<template>
  <EggPreloader :show-go-to-home-btn="!getLoading" />
</template>

<script>
import api from "@/api/api";
import FormatPriceMixin from "@/mixins/common/FormatPriceMixin";
import { mapActions, mapGetters, mapMutations } from "vuex";
import SetProcessingMixin from "@/mixins/general/SetProcessingMixin";
import AppointEggPreloaderMixin from "../mixins/common/AppointEggPreloaderMixin";
import EggPreloader from "../components/utils/EggPreloader";

export default {
  name: "ChoicePage",
  components: { EggPreloader },
  mixins: [FormatPriceMixin, SetProcessingMixin, AppointEggPreloaderMixin],
  data: () => ({
    balance: 0,
  }),
  computed: {
    ...mapGetters({
      getLoading: "general/getLoading",
    }),
  },
  methods: {
    ...mapActions({
      loadBalance: "wallet/loadBalance",
    }),
    ...mapMutations({
      setBalanceToppedUp: "wallet/setBalanceToppedUp",
    }),
    async useCurrencyCard() {
      try {
        this.setLoading(true);
        const code = this.$route.query.code;
        const character = await api.useCurrencyCardAsCharacter(code);
        this.race = character.race;
      } catch (e) {
        this.setError({
          message: e.response.data.error.message,
        });
      } finally {
        this.setLoading(false);
      }
    },
  },
  async mounted() {
    this.balance = +this.$route.query.balance;
    await this.useCurrencyCard();
  },
};
</script>

<style scoped></style>
