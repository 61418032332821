export default {
  data: () => ({
    race: null,
  }),
  computed: {
    animalRace() {
      return this.race === "animals";
    },
    creatureRace() {
      return this.race === "creatures";
    },
    alienRace() {
      return this.race === "aliens";
    },
  },
};
